












































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OWebsiteGenerate: () =>
      import("@/components/organisms/event/website/o-website-generate.vue"),
    OWebsiteSummary: () =>
      import("@/components/organisms/event/website/o-website-summary.vue"),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
    }>({
      stepper: 1,
      data: {
        domain: "",
        name: undefined,
        title: "",
        dbname: "",
        wpuser: "galop-admin",
        wppass: "",
        wpemail: "",
        isNew: true,
      },
    });

    const dataSummary = computed(() => ({
      data: model.data,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      eventType: "",
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (model.data.isNew && !model.data.title)
          errors.push({ step: 1, error: "Podaj tytuł wydarzenia." });
        if (model.data.isNew && !model.data.dbname)
          errors.push({ step: 1, error: "Podaj nazwę bazy danych" });
        if (model.data.isNew && !model.data.wpuser)
          errors.push({ step: 1, error: "Podaj nazwę użytkownika." });
        if (model.data.isNew && !model.data.wppass)
          errors.push({ step: 1, error: "Podaj hasło użytkownika." });
        if (model.data.isNew && !model.data.wpemail)
          errors.push({ step: 1, error: "Podaj e-mail administratora." });

        return errors;
      }),
      event: {},
      items: {},
      controlList: true,
    });

    const isStepEditable = () => {
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    const fetchControlList = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`website/${root.$route.params.id}/requirements`)
        .then(({ data: { requirements } }) => {
          state.items = requirements;

          state.controlList =
            (requirements.registrationFormTemplate ||
              requirements.secondaryRegistrationFormTemplate) &&
            requirements.registrationFees &&
            (requirements.registrationMail ||
              requirements.secondaryRegistrationMail) &&
            (requirements.summaryPage || requirements.secondarySummaryPage) &&
            (!requirements.freeRegistration
              ? requirements.invoiceEvent && !requirements.freeRegistration
              : requirements.freeRegistration)
              ? false
              : true;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchControlList);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          model.data.title = event.name.replace(/[^a-zA-Z ]/g, "");
          model.data.dbname = `db${event.name
            .split(" ")
            .map((el: any) => el[0])
            .join("")
            .toLowerCase()
            .replace(/[^a-zA-Z ]/g, "")}`;
          state.event = event;
          state.eventType = event.type;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    watch(() => model.data.website, fetchData);

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        domain: model.data.domain || undefined,
        name: model.data.name || undefined,
        title: model.data.isNew ? model.data.title : undefined,
        dbName: model.data.isNew ? model.data.dbname : undefined,
        wpUser: model.data.isNew ? model.data.wpuser : undefined,
        wpPassword: model.data.isNew ? model.data.wppass : undefined,
        wpEmail: model.data.isNew ? model.data.wpemail : undefined,
        isNew: model.data.isNew,
      };

      state.loading = true;

      axiosInstance
        .post(`website/${root.$route.params.id}/wordpress`, data, {
          timeout: 2000000,
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          model.stepper = 1;
          model.data = {
            domain: "",
            name: "",
            title: "",
            dbname: "",
            wpuser: "galop-admin",
            wppass: "",
            wpemail: "",
          };
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return "Nie znaleziono eventu";
        case 406:
          return "Istnieją już takie dane strony www";
        case 409:
          return "Nazwa katalogu, bazy danych lub domena już istnieje";
        case 410:
          return "Brak formularza rejestracyjnego";
        case 411:
          return "Brak opłat rejestracyjnych";
        case 412:
          return "Brak wiadomości po rejestracji";
        case 413:
          return "Brak danych fakturowych w formularzu rejestracyjnym";
        case 414:
          return "Brak szablonu podsumowania rejestracji";
        case 500:
          return "Błąd serwera, spróbuj jeszcze raz";
        case 503:
          return "Nie można połączyć się z serwerem";
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return {
      model,
      state,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage,
    };
  },
});
